import React, { useEffect, useState } from 'react';
import { Col, FormGroup, Input, Label } from 'reactstrap';
import axios from 'axios';
import { apiconfig } from '../../../shared/apiconfig';
import { updateObject } from '../../../shared/utility';

export default function ModalSelectsComponents(props) {
    // region variables
    const [sedi, setSedi] = useState([]);
    const [docenti, setDocenti] = useState([]);
    const [studenti, setStudenti] = useState([]);
    const [materie, setMaterie] = useState([]);
    const [userData, setUserData] = useState(null);

    const [state, setState] = useState({ sedi: [], docenti: [], studenti: [], materie: [], aule: [], id_sede: '8' });
    // endregion

    // region lifeCycle
    useEffect(() => {
        if (props.attivo) {
            let init = { sedi: [], docenti: [], studenti: [], materie: [], aule: [], id_sede: '8' };
            if (!props.isModify) setState(init);
            else {
                setState({
                    ...init,
                    id_sede: props.data.sede_planning.id,
                    docentiNuovoEvento: props.data.id_docente,
                    materieNuovoEvento: props.data.id_materia,
                    studentiNuovoEvento: props.data.id_studente,
                    voto: props.data.voto
                });
            }
        }
    }, [props.attivo]);
    // onStart
    useEffect(() => {
        if (props.isAdmin || props.isStudente) caricaElencoMaterie();
        if (props.studentiTut) {
            setStudenti(props.studentiTut);
            updateState('caricamentoStudenti', true);
        }
        if (!props.isStudente && !props.isDocente) {
            caricaElencoSedi();
            caricaElencoDocenti();
            caricaElencoStudenti();
            caricaDocentiRef();
        }
    }, []);
    useEffect(() => {
        props.setData(state);
    }, [state]);
    useEffect(() => {
        if (props.isStudente || props.isDocente) {
            if (localStorage.user) {
                let { id } = JSON.parse(localStorage.user);
                setUserData(props.userData);
                setTimeout(() => {
                    if (props.isStudente)
                        _setState({
                            studentiNuovoEvento: id
                        });
                    else
                        _setState({
                            docentiNuovoEvento: id
                        });
                    _setState({
                        id_sede: props.userData.sedi[0]?.id + ''
                    });
                    setSedi([...new Map(props.userData.sedi.map(item => [item['id'], item])).values()]);
                }, 50);
            }
        }
    }, []);
    useEffect(() => {
        if (userData && props.isDocente) {
            setMaterie(userData?.materie || []);
            caricaElencoStudentiD();
        }
    }, [userData]);
    // endregion

    // region functions
    const _setState = data => {
        setState(prevState => ({ ...prevState, data }));
    };
    function updateState(key, value) {
        let temp = { ...state };
        temp[key] = value;
        setState(prevState => ({ ...prevState, ...temp }));
    }
    function caricaElencoSedi() {
        let params = { params: { disattivata: 0 } };
        axios
            .get(apiconfig().baseURL + 'sediadm', params)
            .then(response => {
                let sediConAule = [];
                response.data.map(sede => {
                    sediConAule.push({ id: sede.id + '', nome: sede.nome });
                    sede.aule.map(aula => {
                        sediConAule.push({ id: sede.id + '-' + aula.id, nome: sede.nome + ' - Aula: ' + aula.aula });
                    });
                });
                setSedi(response.data);
                updateState('caricamentoSedi', true);
            })
            .catch(error => {
                updateState('caricamentoSedi', true);
            });
    }
    function caricaElencoMaterie() {
        const params = { params: { disattivata: 0 } };
        let path = 'materie';
        axios
            .get(apiconfig().baseURL + path, params)
            .then(response => {
                setMaterie(response.data);
                updateState('caricamentoMaterie', true);
            })
            .catch(error => {
                updateState('caricamentoMaterie', true);
            });
    }
    function caricaDocentiRef() {
        axios
            .get(apiconfig().baseURL + 'docentiref', {})
            .then(response => {
                const docentiref = response.data.map(el => {
                    return { id: el.id_docente };
                });
                updateState('docentiRefUtente', docentiref);
            })
            .catch(error => {});
    }

    function caricaElencoDocenti() {
        let params;
        let path;
        path = 'users';
        params = { params: { is_docente: 1 } };
        axios
            .get(apiconfig().baseURL + path, params)
            .then(response => {
                const nuoviDocenti = response.data.map(doc => {
                    return updateObject(doc);
                });
                setDocenti(nuoviDocenti);
                updateState('caricamentoDocenti', true);
            })
            .catch(error => {
                console.error(error);
                updateState('caricamentoDocenti', true);
            });
    }
    function caricaElencoStudenti() {
        let params;
        let path;
        path = 'users';
        params = { params: { is_studente: 1 } };
        if (props.studentiTut) {
            setStudenti(props.studentiTut);
            updateState('caricamentoStudenti', true);
        } else
            axios
                .get(apiconfig().baseURL + path, params)
                .then(response => {
                    setStudenti(response.data);

                    updateState('caricamentoStudenti', true);
                })
                .catch(error => {
                    updateState('caricamentoStudenti', true);
                });
    }
    function caricaElencoStudentiD() {
        let path;
        path = 'studenti';
        axios
            .get(apiconfig().baseURL + path)
            .then(response => {
                setStudenti(response.data);

                updateState('caricamentoStudenti', true);
            })
            .catch(error => {
                updateState('caricamentoStudenti', true);
            });
    }
    function handleChangeStudentiNuovoEvento(data) {
        updateState('studentiNuovoEvento', data.target.value);
    }
    function handleChangeDocentiNuovoEvento(data) {
        updateState('docentiNuovoEvento', data.target.value);
    }
    function handleChangeMaterieNuovoEvento(data) {
        updateState('materieNuovoEvento', data.target.value);
        // updateState('id_materia', data.target.value);
    }
    function handleChangeSedeNuovoEvento(ev) {
        updateState('id_sede', ev.target.value);
    }
    // endregion
    return (
        <>
            <Col>
                <FormGroup className="mb-3">
                    <Label htmlFor="sedeNuovoPlanning" className="mr-1">
                        Sede
                    </Label>
                    <Input
                        type="select"
                        id="sedeNuovoPlanning"
                        value={state.id_sede}
                        onChange={handleChangeSedeNuovoEvento}
                    >
                        <option value="0">(Scegliere una sede)</option>
                        {sedi.map(el => (
                            <option key={el.id} value={el.id}>
                                {el.nome}
                            </option>
                        ))}
                    </Input>
                </FormGroup>
                <FormGroup row>
                    <Col xs="12" sm="3" md="3">
                        <Label>Studente</Label>
                    </Col>
                    <Col xs="12" sm="9" md="9">
                        {userData && props.isStudente && !props.studentiTut ? (
                            userData.cognome + ' ' + userData.nome
                        ) : (
                            <FromMultiToSingleSelect
                                data={studenti.map(x => {
                                    return { id: x.id, nome: x.cognome + ' ' + x.nome };
                                })}
                                textField="nome"
                                minLength={3}
                                value={state.studentiNuovoEvento}
                                onChange={handleChangeStudentiNuovoEvento}
                            />
                        )}
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col xs="12" sm="3" md="3">
                        <Label>Materie</Label>
                    </Col>
                    <Col xs="12" sm="9" md="9">
                        <FromMultiToSingleSelect
                            data={materie}
                            textField="materia"
                            minLength={3}
                            value={state.materieNuovoEvento}
                            onChange={handleChangeMaterieNuovoEvento}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col xs="12" sm="3" md="3">
                        <Label>Voto</Label>
                    </Col>
                    <Col xs="12" sm="9" md="9">
                        <Input
                            type="select"
                            id="selectVoto"
                            value={state.voto ? state.voto : ''}
                            onChange={ev => {
                                let temp = { ...state };
                                temp.voto = ev.target.value;
                                setState(temp);
                            }}
                        >
                            <option value=""></option>
                            {['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'].map(x => {
                                return (
                                    <option key={x} value={x}>
                                        {x}
                                    </option>
                                );
                            })}
                        </Input>
                    </Col>
                </FormGroup>
            </Col>
        </>
    );
}
export const FromMultiToSingleSelect = ({ data, textField, value, onChange }) => {
    return (
        <Input type="select" value={value} onChange={onChange}>
            <option value={''}></option>
            {data.map(e => (
                <option key={e.id} value={e.id}>
                    {e[textField]}
                </option>
            ))}
        </Input>
    );
};
