import moment from 'moment/moment';
import axios from 'axios';
import { apiconfig } from '../../shared/apiconfig';

export const MAPPED_STATUS = {
    standby: {
        color: 'warning',
        label: 'In attesa'
    },
    pending: {
        color: 'warning',
        label: 'In attesa'
    },
    accepted: {
        color: 'success',
        label: 'Accettata'
    },
    rejected: {
        color: 'danger',
        label: 'Rifiutata'
    }
};
export const Notification_history_stab = [
    {
        status: 'accepted',
        studente: 'Giuseppe Rossi',
        date: moment(new Date()).format('DD/MM/YYYY'),
        note: 'Possiamo spostare le lezioni al venerdi? Che mio figlio ha problemi di stomaco e di matematica sono un testo lungo lo uso per provare se va acapo correttamente',
        response: "Certo allora cambio l'appuntamento per venerdi alla stessa ora"
    },
    {
        status: 'rejected',
        date: moment(new Date()).add(-4, 'month').format('DD/MM/YYYY'),
        studente: 'Mario Bianchi',
        note: 'Il giorno 12 non ci sono',
        response: 'Ok'
    },
    {
        status: 'rejected',
        date: moment(new Date()).add(-33, 'days').format('DD/MM/YYYY'),
        studente: 'Fabrizio Verdi',
        note: 'Possiamo spostare le lezioni al lunedi? Che mio figlio ha problemi di stomaco',
        response: 'No siamo impegnati'
    }
];
export const New_notification_stab = [
    {
        status: 'standby',
        studente: 'Giuseppe Rossi',
        date: moment(new Date()).format('DD/MM/YYYY'),
        note: 'Possiamo spostare le lezioni al venerdi? Che mio figlio ha problemi di stomaco e di matematica sono un testo lungo lo uso per provare se va acapo correttamente',
        response: "Certo allora cambio l'appuntamento per venerdi alla stessa ora"
    },
    {
        status: 'standby',
        date: moment(new Date()).add(-4, 'month').format('DD/MM/YYYY'),
        studente: 'Mario Bianchi',
        note: 'Il giorno 12 non ci sono'
        // response: 'Ok'
    },
    {
        status: 'standby',
        date: moment(new Date()).add(-33, 'days').format('DD/MM/YYYY'),
        studente: 'Fabrizio Verdi',
        note: 'Possiamo spostare le lezioni al lunedi? Che mio figlio ha problemi di stomaco'
        // response: 'No siamo impegnati'
    }
];
export function setToMonday(date) {
    var day = date.getDay() || 7;
    if (day !== 1) date.setHours(-24 * (day - 1));
    return date;
}
export function modifyRequest(data, id) {
    return new Promise((resolve, reject) => {
        axios
            .patch(apiconfig().baseURL + 'richiesteStudente/' + id, data)
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.response.data);
            });
    });
}
export function modifyEvaluation(data) {
    return new Promise((resolve, reject) => {
        axios
            .patch(
                apiconfig().baseURL + 'modificaValutazione',
                {
                    ...data
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.response.data);
            });
    });
}
export function getWeekInterval(i) {
    return (
        moment(
            setToMonday(
                moment(new Date())
                    .add(7 * (i + 1), 'days')
                    .toDate()
            )
        ).format('DD/MM') +
        '-' +
        moment(
            setToMonday(
                moment(new Date())
                    .add(7 * (i + 1), 'days')
                    .toDate()
            )
        )
            .add(6, 'days')
            .format('DD/MM')
    );
}
