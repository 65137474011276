import React, { useEffect, useState } from 'react';
import { FormGroup, Input } from 'reactstrap';
import { Tab, TabList, Tabs } from 'react-tabs';
import CIcon from '@coreui/icons-react';
import { cilInfo, cilPlus } from '@coreui/icons';
import TooltipItem from '../../../common/TooltipItem';
const INIT_SECTION = [
    {
        name: 'Attenzione_studente',
        points: 1,
        info:
            ' 1 – 2: Minima attenzione \n' +
            ' 3 - 4: Poca attenzione \n' +
            ' 5 – 6: Discreta attenzione\n' +
            ' 7 – 8: Buona attenzione \n' +
            '9 – 10: Ottima attenzione \n'
    },
    {
        name: 'Comprensione_argomenti',
        points: 1,
        info:
            ' 1 – 2: Minima comprensione dell’argomento \n' +
            ' 3 – 4: Poca comprensione dell’argomento\n' +
            ' 5 - 6: Discreta comprensione dell’argomento\n' +
            ' 7 - 8: Buona comprensione dell’argomento\n' +
            '9 - 10: Ottima comprensione dell’argomento\n'
    },
    {
        name: 'Completamento_argomenti_della_lezione',
        points: 1,
        info:
            ' 1 - 2: Minimo completamento argomenti della lezione 10-20 %\n' +
            ' 3 - 4: Insufficiente completamento argomenti della lezione 30-40 %\n' +
            ' 5 - 6: Discreto completamento argomenti della lezione 50-60 %\n' +
            ' 7 – 8: Buono completamento argomenti della lezione  70-80 %\n' +
            '9 - 10: Ottimo completamento argomenti della lezione 90-100 %\n'
    },
    {
        name: 'Completamento_esercizi_della_lezione',
        points: 0,
        info:
            ' 1 - 2: Minimo completamento esercizi della lezione 10-20 %\n' +
            ' 3 - 4: Insufficiente completamento esercizi della lezione 30-40 %\n' +
            ' 5 - 6: Discreto completamento esercizi della lezione 50-60 %\n' +
            ' 7 - 8: Buon completamento esercizi della lezione 70-80 %\n' +
            '9 - 10: Ottimo completamento esercizi della lezione 90-100 %\n'
    },
    {
        name: 'Padronanza_argomento_trattato ',
        points: 1,
        info:
            ' 1 - 2: Minima padronanza argomento trattato 10-20 %\n' +
            ' 3 - 4: Insufficiente padronanza argomento trattato 30-40%\n' +
            ' 5 - 6: Discreta padronanza argomento trattato 50-60%\n' +
            ' 7 - 8: Buona padronanza argomento trattato 70-80%\n' +
            '9 - 10: Ottima padronanza argomento trattato 90-100% \n'
    }
];
export default function StudentsPoints(props) {
    // region variables
    const [visible, setVisible] = useState(!props.newAdmin);
    const [sections, setSections] = useState([]);
    const [materia, setMateria] = useState('');
    const [modifySection, setModifySection] = useState('');
    const [modifyValue, setModifyValue] = useState('');
    const [modifyTemp, setModifyTemp] = useState(false);
    const [annotazioni, setAnnotazioni] = useState('');
    const [data, setData] = useState({});
    const [materie, setMaterie] = useState([]);
    const [selectedSection, setSelectedSection] = useState('');
    const [, setOldData] = useState({});
    const [name, setName] = useState('');
    const [setted, setSetted] = useState(false);
    const [settedenot, setSettedenot] = useState(false);
    // endregion
    // region lifeCycle
    useEffect(() => {
        props.setMateria && props.setMateria(materia);
        setSetted(false);
    }, [materia]);
    useEffect(() => {
        if (props.adminRequest && props.initSection) {
            setData(props.initSection);
        }
    }, [props.reset]);
    // onStart
    useEffect(() => {
        if (props.evento) {
            setMaterie(props.evento?.materie);
            if (!props.setSelectedMat || !materia)
                setMateria(props.evento?.materie && props.evento?.materie[0]?.id + '');
        }
    }, [props.evento]);
    useEffect(() => {
        setSetted(false);
    }, [props.reload]);
    useEffect(() => {
        if (!props.attivo && !props.adminRequest) {
            setSections([]);
            setMateria('');
            setModifySection('');
            setModifyValue('');
            props.setMateria && props.setMateria('');
            setModifyTemp(false);
            setAnnotazioni('');
            setData({});
            setMaterie([]);
            setSelectedSection('');
            props.setInitialAnnotation && props.setInitialAnnotation('');
            props.setInitialData({});
            setOldData({});
            setName('');
            setSetted(false);
            setSettedenot(false);
        } else console.info('mi apro');
    }, [props.attivo]);

    useEffect(() => {
        if (props.eventoDetail?.length > 0) {
            if (!setted) {
                let temp = {};
                let tempSections = [];
                props.eventoDetail.forEach(e => {
                    try {
                        temp[e.id_materia] = JSON.parse(e.valutazioni);
                        Object.keys(temp[e.id_materia]).forEach(e => {
                            tempSections.push(e);
                        });
                    } catch (err) {
                        console.error(err);
                    }
                });
                setSections(tempSections);
                setAnnotazioni(props.eventoDetail[0].annotazioni);
                props.setInitialAnnotation && props.setInitialAnnotation(props.eventoDetail[0].annotazioni);
                setData(temp);
                setSelectedSection(Object.keys(temp[materia || props.evento?.materie[0]?.id] || {})[0]);
                props.setInitialData(JSON.parse(JSON.stringify(temp)));
                setSetted(true);
            }
        } else {
            if (!settedenot) {
                props.setInitialData({});
                props.setInitialAnnotation && props.setInitialAnnotation('');
                setSettedenot(true);
            }
        }
    }, [props.eventoDetail, props.eventoDetail?.length > 0]);
    useEffect(() => {
        props.setTopic && props.setTopic(annotazioni);
    }, [annotazioni]);

    useEffect(() => {
        props.setSection(data);
    }, [data]);
    useEffect(() => {
        if (props.selectedMat !== materia && props.setSelectedMat)
            handleMateriaChange({ target: { value: props.selectedMat } });
    }, [props.selectedMat]);
    // endregion;
    // region functions
    const handleSectionChange = (ev, i, forceEmpty) => {
        let temp = JSON.parse(JSON.stringify({ ...data }));
        let number = parseInt(ev.target.value);
        if (number <= 0) number = 1;
        if (number > 10) number = 10;
        if (forceEmpty) number = '';
        else if ((i === 3 && isNaN(number)) || !number) number = ev.target.value;
        else if (!forceEmpty) if (!number || isNaN(number)) return;
        temp[materia][selectedSection][i].points = number;
        setData(temp);
    };
    const handleMateriaChange = event => {
        setMateria(event.target.value);
        if (typeof data[event.target.value] === 'object' && Object.keys(data[event.target.value])?.length > 0) {
            setSelectedSection(Object.keys(data[event.target.value])[0]);
        }
    };
    useEffect(() => {
        if (props.setSelectedMat) props.setSelectedMat(materia);
    }, [selectedSection]);
    const handleTopicBlur = () => {
        if (sections.includes(name.toLowerCase())) {
            props.avviso('Attenzione', 'Non puoi inserire due argomenti con lo stesso nome');
        } else if (name) {
            let temp = [...sections];
            temp.push(name.toLowerCase());
            let tempData = { ...data };
            if (!tempData[materia]) {
                tempData[materia] = {};
            }
            if (!tempData[name]) {
                tempData[materia][name] = INIT_SECTION;
            }
            setData(tempData);
            setSections(temp);
            setSelectedSection(name);
            setName('');
        }
    };
    const deleteSection = e => {
        let temp = { ...data };
        if (!temp[materia]) return;
        delete temp[materia][e];
        if (selectedSection === e) {
            if (Object.keys(temp[materia]).length > 0) setSelectedSection(Object.keys(temp[materia])[0]);
        }
        let tempSection = [...sections];
        let index = sections.findIndex(el => el === e);
        tempSection.splice(index, 1);
        setSections(tempSection);
        setData(temp);
    };
    // endregion
    let addReadOnlyHeight = props.readOnly && !props.onRequest ? 19 : 0;
    return (
        <>
            <div
                className={'points' + (visible ? ' open' : '')}
                style={{
                    ...style.container,
                    height:
                        materie?.length > 0 && (visible ? (!props.adminRequest ? 630 : 420) + addReadOnlyHeight : 50),
                    overflowY: 'hidden'
                }}
            >
                {materie?.length > 0 && (
                    <h5 style={style.title} onClick={() => setVisible(!visible)}>
                        <i className={'fa fa-book'} style={{ fontSize: 22 }}></i>
                        &nbsp; {props.adminRequest || props.newAdmin ? 'Report' : 'Inserisci Report'}
                        {props.docente && !props.is_docente ? (
                            <a
                                target={'_blank'}
                                href={'#/datiutente/' + props.docente.id}
                                style={{ marginLeft: 100, fontSize: 16 }}
                            >
                                Docente: {props.docente.cognome + ' ' + props.docente.nome}
                            </a>
                        ) : null}
                        {/*{(props.studente) ? (*/}
                        {/*    <a*/}
                        {/*        target={'_blank'}*/}
                        {/*        href={'#/datiutente/' + props.studente.id}*/}
                        {/*        style={{ marginLeft: 100, fontSize: 16 }}*/}
                        {/*    >*/}
                        {/*        Studente: {props.studente.cognome + ' ' + props.studente.nome}*/}
                        {/*    </a>*/}
                        {/*) : null}*/}
                        <i
                            className={visible ? 'fa fa-chevron-circle-up' : 'fa fa-chevron-circle-down'}
                            style={{ marginLeft: 'auto' }}
                        />
                    </h5>
                )}

                {!props.adminRequest && materie?.length > 0 && (
                    <FormGroup style={{ marginTop: 12 }}>
                        <h5>Materia della lezione</h5>
                        <Input
                            type="select"
                            name="materiaLezione"
                            id="selectMaterie"
                            value={materia}
                            onChange={handleMateriaChange}
                        >
                            {materie?.length > 0 ? (
                                materie?.map(e => <option value={e.id}>{e.materia}</option>)
                            ) : (
                                <option value={'0'}></option>
                            )}
                        </Input>
                    </FormGroup>
                )}
                {props.readOnly && !props.onRequest ? (
                    <h6 style={{ color: 'red', fontWeight: 'bold', marginTop: -5, marginBottom: 12 }}>
                        Il report e' stato accettato
                    </h6>
                ) : (
                    ''
                )}
                <Tabs style={{ marginBottom: -10, paddingLeft: 0 }}>
                    <TabList
                        style={{
                            margin: 0,
                            display: 'flex',
                            borderStartStartRadius: 4,
                            paddingLeft: 0,
                            overflow: 'auto hidden'
                        }}
                    >
                        {((data && data[materia] && Object.keys(data[materia])) || [])?.map((e, i) => (
                            <Tab
                                style={{
                                    ...style.tabStyle,
                                    borderStartStartRadius: i === 0 ? 4 : 0,
                                    borderBottom: selectedSection !== e && '1px solid  #b1b7c1',
                                    fontWeight: selectedSection === e && 'bold',
                                    paddingLeft: 12
                                }}
                                onClick={() => setSelectedSection(e)}
                            >
                                <div
                                    style={{ height: 35.48, display: 'flex', alignItems: 'center' }}
                                    key={e + i}
                                    className={'l-h-35'}
                                >
                                    {modifySection !== e ? (
                                        <>
                                            {e}
                                            &nbsp; &nbsp;
                                            {!props.adminRequest && !props.readOnly && !props.newAdmin ? (
                                                <>
                                                    <i
                                                        className={'fas fa-pen'}
                                                        onClick={ev => {
                                                            ev.stopPropagation();
                                                            setSelectedSection(e);
                                                            setTimeout(() => {
                                                                setModifySection(e);
                                                            }, 50);
                                                        }}
                                                        style={style.createStyle}
                                                    />
                                                    &nbsp; &nbsp;
                                                    <i
                                                        className={'fas fa-minus'}
                                                        onClick={ev => {
                                                            ev.stopPropagation();
                                                            deleteSection(e);
                                                        }}
                                                        style={style.minusStyle}
                                                    />
                                                </>
                                            ) : null}
                                        </>
                                    ) : (
                                        <Input
                                            value={modifyValue || modifySection}
                                            style={{
                                                width: 7.7 * (modifyValue || modifySection).length + 18 + 12,
                                                marginRight: 40
                                            }}
                                            onFocus={() => setModifyTemp(false)}
                                            autoFocus
                                            onBlur={() => {
                                                if (e === (modifyValue || modifySection)) {
                                                    setModifySection(null);
                                                    setModifyValue('');
                                                    return;
                                                }
                                                if (sections.includes(modifyValue.toLowerCase()) && modifyTemp) {
                                                    props.avviso(
                                                        'Attenzione',
                                                        'Non puoi inserire due argomenti con lo stesso nome'
                                                    );
                                                    setModifySection(null);
                                                    setModifyValue('');
                                                    return;
                                                }
                                                let temp = { ...data };
                                                if (!temp[materia]) return;
                                                let obj = JSON.parse(JSON.stringify(temp[materia][e]));
                                                delete temp[materia][e];
                                                temp[materia][modifyValue] = obj;
                                                setSelectedSection(modifyValue);
                                                let tempSection = [...sections];
                                                let index = sections.findIndex(el => el === e);
                                                tempSection[index] = modifyValue.toLowerCase();
                                                setSections(tempSection);
                                                setModifyTemp(null);
                                                setData(temp);
                                                setModifySection('');
                                                setModifyValue('');
                                            }}
                                            onChange={event => {
                                                setModifyTemp(true);
                                                setModifyValue(event.target.value);
                                            }}
                                        />
                                    )}
                                </div>
                            </Tab>
                        ))}
                        {!props.adminRequest && !props.readOnly && !props.newAdmin && materie?.length > 0 && (
                            <Tab
                                style={{
                                    borderStartStartRadius: sections.length === 0 ? 4 : 0,
                                    borderStartEndRadius: sections.length === 0 ? 0 : 4,
                                    ...style.tabStyle
                                }}
                            >
                                <div className={'d-flex align-items-center'}>
                                    {props.adminRequest ? <>&nbsp; &nbsp; </> : null}
                                    <CIcon icon={cilPlus} style={style.plusStyle} />
                                    &nbsp;&nbsp;&nbsp;{' '}
                                    <Input
                                        style={{ maxWidth: 147 }}
                                        id={'new'}
                                        value={name}
                                        onKeyUp={event => {
                                            if (event.key === 'Enter') {
                                                handleTopicBlur();
                                            }
                                        }}
                                        onChange={event => setName(event.target.value)}
                                        onBlur={handleTopicBlur}
                                        onClick={event => event.stopPropagation()}
                                        placeholder={'Nuovo Argomento'}
                                    ></Input>
                                </div>
                            </Tab>
                        )}
                    </TabList>
                    <div style={style.pointSectionStyle}>
                        {!(data[materia] && data[materia][selectedSection]) &&
                            (!materie?.length > 0 ? (
                                <div style={style.noTopic}>Devi inserire una materia nell'evento per continuare</div>
                            ) : (
                                <div style={style.noTopic}> Devi inserire o scegliere un argomento per continuare</div>
                            ))}
                        {((data && data[materia] && data[materia][selectedSection]) || INIT_SECTION).map &&
                            ((data && data[materia] && data[materia][selectedSection]) || INIT_SECTION)?.map((e, i) => (
                                <div className={'student-report-div'} style={style.collapseContent}>
                                    <span
                                        style={{
                                            margin: 0,
                                            display: 'flex',
                                            alignItems: 'baseline',
                                            position: 'relative'
                                        }}
                                    >
                                        {e.name.replace(/_/g, ' ')}{' '}
                                        {data[materia] && data[materia][selectedSection] && (
                                            <i
                                                id={'info-section-' + i}
                                                className={'fa fa-info-circle cursor-pointer sm-hidden'}
                                                style={{ color: '#20a8d8', marginLeft: 12, fontSize: 18 }}
                                            />
                                        )}
                                        <TooltipItem points content={INIT_SECTION[i].info} />
                                    </span>
                                    {i === 3 && e.points === 0 ? (
                                        <div style={{ marginLeft: 'auto' }}>
                                            Non Presenti &nbsp;
                                            {!props.is_docente && !props.readOnly && (
                                                <i
                                                    className={'fa fa-pencil cursor-pointer'}
                                                    style={{ color: '#0af' }}
                                                    onClick={() => {
                                                        handleSectionChange({ target: { value: 1 } }, i);
                                                    }}
                                                />
                                            )}
                                            &nbsp; &nbsp;
                                        </div>
                                    ) : (
                                        <div style={{ marginLeft: 'auto' }} className={'d-flex align-items-center'}>
                                            {i === 3 && !props.is_docente && !props.readOnly && (
                                                <i
                                                    className={'fa fa-remove cursor-pointer mr-2 text-danger'}
                                                    onClick={() => {
                                                        let temp = JSON.parse(JSON.stringify({ ...data }));
                                                        temp[materia][selectedSection][i].points = 0;
                                                        setData(temp);
                                                    }}
                                                />
                                            )}
                                            <Input
                                                style={{ width: window.innerWidth >= 568 ? 100 : 40 }}
                                                value={e.points}
                                                disabled={props.readOnly || props.is_docente}
                                                type={'number'}
                                                onBlur={() => {
                                                    if (!e.points) {
                                                        // let temp = { ...oldData };
                                                        // handleSectionChange({ target: { value: temp[i].points } }, i);
                                                    }
                                                }}
                                                onFocus={() => {
                                                    // setOldData({ ...data });
                                                    // setTimeout(
                                                    //     () => handleSectionChange({ target: { value: '' } }, i, true),
                                                    //     100
                                                    // );
                                                }}
                                                name={e.name}
                                                onChange={ev => handleSectionChange(ev, i)}
                                            />
                                        </div>
                                    )}
                                </div>
                            ))}
                    </div>
                    &nbsp;
                    {!props.adminRequest && (
                        <>
                            <h5>Annotazioni docente / Compiti assegnati</h5>
                            <FormGroup>
                                <Input
                                    type="textarea"
                                    disabled={props.readOnly}
                                    name="text"
                                    value={annotazioni}
                                    rows={3}
                                    onChange={arg => setAnnotazioni(arg.target.value)}
                                />
                            </FormGroup>
                        </>
                    )}
                </Tabs>
            </div>
        </>
    );
}
const style = {
    tabStyle: {
        background: 'white',
        border: 'none',
        minWidth: 'max-content',
        borderRadius: 0,
        borderBottom: '1px solid  #b1b7c1',
        textTransform: 'capitalize',
        borderTop: '1px solid  #b1b7c1',
        borderLeft: '1px solid  #b1b7c1',
        borderRight: '1px solid  #b1b7c1'
    },
    noTopic: {
        position: 'absolute',
        left: 0,
        right: 0,
        zIndex: 100000,
        justifyContent: 'center',
        display: 'flex',
        color: 'white',
        alignItems: 'center',
        top: 0,
        bottom: 0,
        background: '#000a'
    },
    plusStyle: {
        borderRadius: '50%',
        border: '1px solid',
        padding: 2,
        minHeight: 17,
        minWidth: 17
    },
    minusStyle: {
        borderRadius: '50%',
        border: '1px solid',
        color: 'red',
        maxWidth: 22,
        fontSize: 9,
        maxHeight: 22,
        minHeight: 10,
        minWidth: 10,
        padding: 3,
        paddingLeft: 4,
        paddingRight: 4
    },
    createStyle: {
        borderRadius: '50%',
        padding: 2,
        minHeight: 17,
        minWidth: 17,
        color: '#5fbfe3'
    },
    pointSectionStyle: {
        background: 'white',
        width: '100%',
        position: 'relative',
        borderRadius: 0,
        border: '1px solid #b1b7c1',
        borderTop: '0px'
    },
    container: {
        width: '100%',
        background: '#eaf5ff',
        border: '2px solid #eaf5ff',
        borderRadius: 8,
        display: 'flex',
        paddingLeft: 12,
        paddingRight: 12,
        flexDirection: 'column',
        marginTop: 16,
        transition: '0.5s',
        overflow: 'hidden',
        marginBottom: 16
    },
    title: {
        display: 'flex',
        flex: 1,
        minHeight: 50,
        margin: 0,
        height: '100%',
        background: '#eaf5ff',
        position: 'sticky',
        top: 0,
        width: '100%',
        cursor: 'pointer',
        fontFamily: 'Segoe UI',
        paddingBottom: 2,
        alignItems: 'center'
    },
    collapseContent: {
        display: 'flex',
        justifyContent: 'start',
        padding: 12,
        alignItems: 'center',
        borderBottom: '1px solid #eaf5ff'
    },
    newSection: {
        height: 40,
        // background: '#a1c8ff',
        textAlign: 'center',
        alignSelf: 'center',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 12
    }
};
