import React, { Component } from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Row,
    Col,
    Input,
    Label,
    Badge,
    ButtonDropdown,
    Card,
    CardHeader,
    CardBody,
    ListGroup,
    ListGroupItem,
    ListGroupItemText,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';
import { convertiDataDaDB } from '../../shared/utility';
import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import TooltipItem from '../../common/TooltipItem';
import ModaleAllegati from '../../common/ModaleAllegati';
import TestoMultilinea from '../../common/TestoMultilinea';
import StudentsPoints from './DlgComponents/StudentsPoints';
import { IfActive } from './DlgComponents/PlanningServices';
import PlanningSchedaStudente from './DlgComponents/PlanningSchedaStudente';
import axios from 'axios';
import { addAuthToken, apiconfig } from '../../shared/apiconfig';
import RichiestaConferma from '../../common/RichiestaConferma';
import { scaricaSME } from '../../services/commons';
import moment from 'moment';
export default class ModaleEventoDocente extends Component {
    user = JSON.parse(localStorage.user);
    constructor(props) {
        super(props);
        this.state = {
            activeTab: '0',
            annotazioniIniziali: '',
            annotazioniModificate: false,
            sezioneAggiungiAllegatoDocVisibile: false,
            modaleAllegatiAttiva: false,
            listaAllegatiRicerca: [],
            dropDownOpen: [],
            selectedStud: null,
            richiestaConferma: {
                attiva: false,
                titolo: '',
                messaggio: ''
            }
        };
        this.modificheAnnotazioniHandle = this.modificheAnnotazioniHandle.bind(this);
        this.mostrasezioneAggiungiAllegatoDoc = this.mostrasezioneAggiungiAllegatoDoc.bind(this);
        this.handleToggle = this.handleToggle.bind(this);
        this.newModification = this.newModification.bind(this);
    }

    toggle(tab) {
        this.setState({
            activeTab: tab
        });
    }

    toggleDropDown = id => {
        let dropDownOpen = this.state.dropDownOpen;
        const idx = dropDownOpen.indexOf(id);
        if (idx == -1) {
            dropDownOpen.push(id);
        } else {
            dropDownOpen.splice(idx, 1);
        }
        this.setState({
            dropDownOpen: dropDownOpen
        });
    };

    modificheAnnotazioniHandle(arg) {
        this.setState({
            annotazioniModificate: true
        });
        this.props.annotazioniDocenteHandler(arg);
    }

    mostrasezioneAggiungiAllegatoDoc = () => {
        const valore = this.state.sezioneAggiungiAllegatoDocVisibile;
        this.setState({
            sezioneAggiungiAllegatoDocVisibile: !valore
        });
    };

    suCompletamentoUpload() {
        this.props.aggiornaAllegatiPlanning();
    }
    handleToggle() {
        if (JSON.stringify(this.state.valutazioniIniziali) !== JSON.stringify(this.state.sections)) {
            this.newModification(false);
        } else if ((this.state.annotazioniIniziali || '') !== this.state.annotazioni + '') {
            this.newModification(false);
        } else this.props.toggle();
    }
    newModification(fromMenu) {
        this.setState({
            richiestaConferma: {
                attiva: true,
                titolo: 'Attenzione',
                messaggio: 'Sono state rilevate delle modifiche non salvate, salvarle?',
                fromMenu
            }
        });
    }
    render() {
        const idDocente =
            this.user.superadmin == 1 || this.user.is_admin == 1
                ? this.props.idDocenteLezioneVisualizzata
                : this.user.id;
        const { argomento, valutazioni } = (this.props.evento.dettagli && this.props.evento.dettagli[0]) || {
            argomento: '',
            valutazioni: null
        };
        if (!this.state.topic && argomento) this.setState({ topic: argomento });
        if (
            this.props.evento.studenti &&
            this.state.selectedStud !== this.props.evento.studenti[0] &&
            !this.state.changed &&
            !this.props.isNotLesson
        ) {
            this.setState({ selectedStud: this.props.evento.studenti[0] });
        }
        if (this.props.isNotLesson && this.state.selectedStud) this.setState({ selectedStud: null });
        if (
            this.user?.is_docente &&
            !this.state.materiaSetted &&
            this.props.evento.materie?.length > 0 &&
            !this.props.forceDocente
        ) {
            this.props.handleChangeMateriaAllegato({ target: { value: this.props.evento?.materie[0]?.id } });
            this.setState({ materiaSetted: true });
        }
        if (this.state.haveToChange) {
            document.getElementById('nomebreve' + this.state.haveToChange).click();
            this.setState({ haveToChange: null });
        }
        return (
            <React.Fragment>
                <IfActive
                    active={this.props.attivo}
                    callBack={() => {
                        addAuthToken();
                        axios
                            .get(apiconfig().baseURL + 'scuole', {})
                            .then(response => {
                                this.setState({
                                    scuole: response.data
                                });
                            })
                            .catch(error => {
                                console.error(error);
                            });
                    }}
                    onClose={() =>
                        this.setState({
                            viewReport: false,
                            selectedStud: [],
                            annotazioni: '',
                            annotazioniIniziali: '',
                            sections: {},
                            valutazioniIniziali: {}
                        })
                    }
                />
                <RichiestaConferma
                    attivo={this.state.richiestaConferma.attiva}
                    annullaModifiche
                    toggle={data => {
                        if (data === 2) {
                            this.setState({
                                richiestaConferma: { attiva: false },
                                viewReport: false,
                                annotazioni: '',
                                valutazioniIniziali: {},
                                annotazioniIniziali: '',
                                sections: {},
                                haveToChange: this.state.richiestaConferma?.fromMenu
                            });
                            if (!this.state.richiestaConferma?.fromMenu) this.props.toggle();
                        } else {
                            if (data === 1) {
                                this.props.salvaAnnotazioniStudente({}, this.state.selectedStud.id, {
                                    annotazioni: this.state.annotazioni,
                                    dati_materie: JSON.stringify(this.state.sections)
                                });
                                this.setState({
                                    richiestaConferma: { attiva: false },
                                    viewReport: false,
                                    valutazioniIniziali: {},
                                    annotazioniIniziali: '',
                                    annotazioni: '',
                                    sections: {},
                                    haveToChange: this.state.richiestaConferma?.fromMenu
                                });
                            }
                            if (!this.state.richiestaConferma?.fromMenu) this.props.toggle();
                        }
                    }}
                    titolo={this.state.richiestaConferma.titolo}
                    messaggio={this.state.richiestaConferma.messaggio}
                />
                {!this.props.forceDocente && (
                    <ModaleAllegati
                        path={'docente'}
                        resetMap={this.props.resetMap}
                        isNotLesson={this.state.isNotLesson}
                        attivo={this.props.modaleAllegatiAttiva}
                        eventi={this.props.eventi}
                        idEvento={this.props.idEventoSelezionato}
                        toggle={this.props.toggleModaleAllegati}
                        idTipoAllegatoNuovo={this.props.idTipoAllegatoNuovo}
                        handleChangeTipoAllegato={this.props.handleChangeTipoAllegato}
                        idMateriaAllegatoNuovo={this.props.idMateriaAllegatoNuovo}
                        selectedStud={this.state.selectedStud}
                        materie={this.props.evento.materie}
                        studenti={this.props.evento.studenti}
                        handleChangeMateriaAllegato={this.props.handleChangeMateriaAllegato}
                        classeAllegatoNuovo={this.props.idClasseAllegatoNuovo}
                        handleChangeClasseAllegato={this.props.handleChangeClasseAllegato}
                        idScuolaAllegatoNuovo={this.props.idScuolaAllegatoNuovo}
                        handleChangeScuolaAllegato={this.props.handleChangeScuolaAllegato}
                        descrAllegatoNuovo={this.props.descrAllegatoNuovo}
                        handleChangeDescrAllegato={this.props.handleChangeDescrAllegato}
                        fileUploaderParams={{ id_studente: this.state.selectedStud?.id }}
                        fileUploaderPath="allegatoTmp"
                        suCompletamento={this.props.suCompletamentoUploadModale}
                        suCompletamentoMap={this.props.suCompletamentoUploadModaleMap}
                        conferma={this.props.ConfermaAllegato}
                        selAllegatoEsistente={this.props.selAllegatoEsistente}
                        mostraSelAllegatoEsistente={true}
                        handleChangeAllegatoEsistente={this.props.handleChangeAllegatoEsistente.bind(this)}
                        handleChangeSelAllegatoEsistente={this.props.handleChangeSelAllegatoEsistente}
                        tipoAllegato={'allegatoDocente'}
                        listaAllegati={this.state.listaAllegatiRicerca}
                        allegatiSelezionati={this.props.allegatiSelezionati}
                    />
                )}
                <Modal
                    isOpen={this.props.attivo}
                    toggle={this.handleToggle}
                    backdrop={true}
                    className={'modal-primary'}
                    size="lg"
                >
                    <ModalHeader>
                        Scheda lezione
                        <Badge
                            color={this.props.evento.online == 1 ? 'warning' : 'success'}
                            style={{ marginLeft: '20px' }}
                        >
                            {' ' + this.props.evento.online == 1 ? 'A DISTANZA' : 'IN PRESENZA'}
                        </Badge>
                    </ModalHeader>
                    <ModalBody>
                        {this.props.evento.docenti || this.props.forceDocente ? (
                            this.props.evento.docenti?.find(el => el.id == idDocente) || this.props.forceDocente ? (
                                <React.Fragment>
                                    {(
                                        this.props.evento.docenti?.find(el => el.id == idDocente) ||
                                        this.props.forceDocente
                                    ).presenza == 0 ? (
                                        <div>
                                            <Button color="danger" onClick={this.props.confermaPresenzaDocente}>
                                                Conferma presenza docente
                                            </Button>
                                        </div>
                                    ) : null}
                                    <h5 style={{ marginTop: 6 }}>
                                        Materie: {this.props.evento.materie.map(el => el.materia).join(', ')}
                                    </h5>
                                    <Tabs>
                                        <TabList>
                                            {this.props.evento.studenti.map(el => {
                                                return (
                                                    <Tab
                                                        key={el.id}
                                                        id={'tab' + el.id}
                                                        onClick={ev => {
                                                            if (this.state.selectedStud !== el) {
                                                                if (
                                                                    JSON.stringify(this.state.valutazioniIniziali) !==
                                                                    JSON.stringify(this.state.sections)
                                                                ) {
                                                                    this.newModification(el.id);
                                                                    ev.preventDefault();
                                                                    ev.stopPropagation();
                                                                } else if (
                                                                    (this.state.annotazioniIniziali || '') !==
                                                                    this.state.annotazioni + ''
                                                                ) {
                                                                    this.newModification(el.id);
                                                                    ev.preventDefault();
                                                                    ev.stopPropagation();
                                                                } else
                                                                    this.setState({
                                                                        selectedStud: el,
                                                                        changed: true,
                                                                        viewReport: false
                                                                    });
                                                            }
                                                        }}
                                                    >
                                                        <i className="icon-user"></i>
                                                        &nbsp;
                                                        <span id={'nomebreve' + el.id}>{el.nome}</span>
                                                        <TooltipItem
                                                            placement="top"
                                                            objectId={'nomebreve' + el.id}
                                                            content={el.nome}
                                                        />
                                                        {el.presenza == 0 ? (
                                                            <React.Fragment>
                                                                <Badge pill color="warning" id={'warning' + el.id}>
                                                                    <i className="fa fa-warning"></i>
                                                                </Badge>
                                                                <TooltipItem
                                                                    placement="top"
                                                                    objectId={'warning' + el.id}
                                                                    content={'Presenza/assenza non impostata'}
                                                                />
                                                            </React.Fragment>
                                                        ) : el.presenza == '2' ? (
                                                            <Label style={{ color: 'red' }}>
                                                                <i className="fa fa-circle"></i>
                                                            </Label>
                                                        ) : (
                                                            <Label style={{ color: 'green' }}>
                                                                <i className="fa fa-circle"></i>
                                                            </Label>
                                                        )}
                                                    </Tab>
                                                );
                                            })}
                                            <Tab>Annotazioni e allegati non pubblici</Tab>
                                        </TabList>
                                        {this.props.evento.studenti.map((el, i) => {
                                            let data = [];
                                            let events = [];
                                            if (this.props.attivo && this.props.evento.dettagli?.length > 0) {
                                                let studentsEvent = this.props.evento.dettagli.filter(
                                                    e => e.id_studente + '' === el.id + ''
                                                );
                                                let docEvent = studentsEvent.filter(
                                                    e =>
                                                        `${e.id_docente}` ===
                                                        `${this.props.idDocenteLezioneVisualizzata || this.user.id}`
                                                );
                                                let materiasEvent = docEvent.filter(
                                                    a => a.id_materia + '' === '' + this.state.materiaEvento
                                                );
                                                if (!this.state.materiaEvento) {
                                                    if (docEvent.length > 0) data = docEvent;
                                                    else data = [{ attivo: false }];
                                                } else {
                                                    if (materiasEvent.length > 0) data = materiasEvent;
                                                    else data = [{ attivo: false }];
                                                }
                                                events = docEvent;
                                            }
                                            let readOnlyReport = data[0]?.attivo;
                                            return (
                                                <>
                                                    <TabPanel key={el.id + 'a'}>
                                                        <h3 style={{ marginBottom: el.studente_sostegno ? 0 : 16 }}>
                                                            Studente:{' '}
                                                            <Link to={'/schedastudenti/' + el.id}>{el.nome}</Link>
                                                        </h3>
                                                        {el.studente_sostegno ? (
                                                            <Badge
                                                                style={{ marginBottom: 16, marginTop: 2 }}
                                                                color={'primary'}
                                                            >
                                                                Studente con sostegno
                                                            </Badge>
                                                        ) : (
                                                            ''
                                                        )}
                                                        <p>
                                                            {el.classe ? <h5>Classe: {el.classe}</h5> : ''}
                                                            {el.scuola ? (
                                                                <h5>
                                                                    Scuola:{' '}
                                                                    {
                                                                        this.state.scuole?.find(e => {
                                                                            return (
                                                                                parseInt(e.id) === parseInt(el.scuola)
                                                                            );
                                                                        }).nome
                                                                    }
                                                                </h5>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </p>

                                                        <Card>
                                                            <CardHeader
                                                                style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    minHeight: 30
                                                                }}
                                                            >
                                                                {!this.props.forceDocente && (
                                                                    <>
                                                                        {el.presenza == 0 ? (
                                                                            <div style={{ marginBottom: 2 }}>
                                                                                <Badge color="danger">
                                                                                    Impostare presenza o assenza
                                                                                </Badge>
                                                                            </div>
                                                                        ) : null}
                                                                        <div>
                                                                            <Input
                                                                                className="form-check-input ml-3"
                                                                                type="checkbox"
                                                                                id={'checkboxPresente' + el.id}
                                                                                name="checkboxPresente"
                                                                                checked={el.presenza == 1}
                                                                                onChange={() =>
                                                                                    this.props.handleClickPresente(
                                                                                        this,
                                                                                        el.id
                                                                                    )
                                                                                }
                                                                            />
                                                                            <Label
                                                                                check
                                                                                className="form-check-label ml-5"
                                                                                htmlFor={'checkboxPresente' + el.id}
                                                                            >
                                                                                Presente
                                                                            </Label>
                                                                            <Input
                                                                                className="form-check-input ml-3"
                                                                                type="checkbox"
                                                                                id={'checkboxAssente' + el.id}
                                                                                name="checkboxAssente"
                                                                                checked={el.presenza == 2}
                                                                                onChange={() =>
                                                                                    this.props.handleClickAssente(
                                                                                        this,
                                                                                        el.id
                                                                                    )
                                                                                }
                                                                            />
                                                                            <Label
                                                                                check
                                                                                className="form-check-label ml-5"
                                                                                htmlFor={'checkboxAssente' + el.id}
                                                                            >
                                                                                Assente
                                                                            </Label>
                                                                        </div>
                                                                        <Button
                                                                            className={'view-report-button'}
                                                                            style={{
                                                                                marginLeft: 'auto',
                                                                                color: 'white'
                                                                            }}
                                                                            onClick={() => {
                                                                                this.setState({
                                                                                    viewReport: !this.state.viewReport
                                                                                });
                                                                            }}
                                                                        >
                                                                            {!this.state.viewReport
                                                                                ? 'Visualizza i report'
                                                                                : 'Visualizza la scheda lezione'}
                                                                        </Button>
                                                                    </>
                                                                )}
                                                            </CardHeader>
                                                            {this.state.viewReport ? (
                                                                <CardBody>
                                                                    <>
                                                                        <StudentsPoints
                                                                            materia={this.state.materiaEvento}
                                                                            readOnly={
                                                                                readOnlyReport || this.props.readOnly
                                                                            }
                                                                            setMateria={data =>
                                                                                this.setState({
                                                                                    materiaEvento: data
                                                                                })
                                                                            }
                                                                            reload={this.state.reloadPoint}
                                                                            attivo={this.props.attivo}
                                                                            avviso={this.props.toggleAvviso}
                                                                            setInitialData={data => {
                                                                                this.setState({
                                                                                    valutazioniIniziali: {
                                                                                        ...data
                                                                                    }
                                                                                });
                                                                            }}
                                                                            setInitialAnnotation={data =>
                                                                                this.setState({
                                                                                    annotazioniIniziali: data
                                                                                })
                                                                            }
                                                                            idDocenteLezioneVisualizzata={
                                                                                this.props.idDocenteLezioneVisualizzata
                                                                            }
                                                                            eventoDetail={events}
                                                                            evento={
                                                                                this.props.attivo
                                                                                    ? this.props.evento
                                                                                    : null
                                                                            }
                                                                            studId={el.id}
                                                                            setTopic={data => {
                                                                                this.setState({
                                                                                    annotazioni: data || ''
                                                                                });
                                                                            }}
                                                                            setSection={data => {
                                                                                this.setState({
                                                                                    sections: data
                                                                                });
                                                                            }}
                                                                        />

                                                                        <PlanningSchedaStudente
                                                                            evento={this.props.evento}
                                                                            attivo={this.props.attivo}
                                                                            el={el}
                                                                            state={this.state}
                                                                            setState={data => {
                                                                                this.setState(data);
                                                                            }}
                                                                            idDocente={idDocente}
                                                                            props={this.props}
                                                                        />
                                                                        {!this.props.readOnly && (
                                                                            <Row
                                                                                style={{
                                                                                    paddingRight: 0
                                                                                }}
                                                                            >
                                                                                <Col
                                                                                    xs="12"
                                                                                    md="12"
                                                                                    sm="12"
                                                                                    style={{
                                                                                        marginBottom: '16px',
                                                                                        display: 'flex',
                                                                                        marginRight: 0,
                                                                                        paddingRight: 0
                                                                                    }}
                                                                                >
                                                                                    <Button
                                                                                        color="primary"
                                                                                        onClick={arg =>
                                                                                            this.props.salvaAnnotazioniStudente(
                                                                                                arg,
                                                                                                el.id,
                                                                                                {
                                                                                                    annotazioni:
                                                                                                        this.state
                                                                                                            .annotazioni,
                                                                                                    dati_materie:
                                                                                                        JSON.stringify(
                                                                                                            this.state
                                                                                                                .sections
                                                                                                        )
                                                                                                },
                                                                                                () =>
                                                                                                    this.setState({
                                                                                                        reloadPoint:
                                                                                                            !this.state
                                                                                                                .reloadPoint
                                                                                                    })
                                                                                            )
                                                                                        }
                                                                                        disabled={false}
                                                                                    >
                                                                                        Salva annotazioni
                                                                                    </Button>
                                                                                </Col>
                                                                            </Row>
                                                                        )}
                                                                    </>
                                                                </CardBody>
                                                            ) : (
                                                                <CardBody>
                                                                    {el.presenza == 1 || el.presenza != 1 ? (
                                                                        <Row>
                                                                            <Col sm="12" xs="12" md="12">
                                                                                <h5>Comunicazioni studente/famiglia</h5>
                                                                                {this.props.evento.studenti_annotazioni?.filter(
                                                                                    x => x.id_studente == el.id
                                                                                ).length == 0
                                                                                    ? 'Nessuna'
                                                                                    : null}
                                                                                <div>
                                                                                    {this.props.evento.studenti_annotazioni
                                                                                        ?.filter(
                                                                                            x => x.id_studente == el.id
                                                                                        )
                                                                                        .map(an => {
                                                                                            return (
                                                                                                <Row
                                                                                                    key={
                                                                                                        'annotazione' +
                                                                                                        an.id_annotazione
                                                                                                    }
                                                                                                >
                                                                                                    <Col
                                                                                                        xs="12"
                                                                                                        md="12"
                                                                                                        sm="12"
                                                                                                    >
                                                                                                        <label
                                                                                                            style={{
                                                                                                                fontStyle:
                                                                                                                    'italic'
                                                                                                            }}
                                                                                                        >
                                                                                                            {an.cognome +
                                                                                                                ' ' +
                                                                                                                an.nome}{' '}
                                                                                                            {' - '}
                                                                                                            {moment(
                                                                                                                an.data_annotazione
                                                                                                            ).format(
                                                                                                                'DD/MM/YYYY HH:mm'
                                                                                                            )}
                                                                                                        </label>
                                                                                                    </Col>
                                                                                                    <Col
                                                                                                        xs="12"
                                                                                                        md="12"
                                                                                                        sm="12"
                                                                                                    >
                                                                                                        {an.annotazione
                                                                                                            .split('\n')
                                                                                                            .map(
                                                                                                                (
                                                                                                                    str,
                                                                                                                    idx
                                                                                                                ) => (
                                                                                                                    <p
                                                                                                                        key={
                                                                                                                            'parAnnotazione' +
                                                                                                                            an.id_annotazione +
                                                                                                                            '_' +
                                                                                                                            idx
                                                                                                                        }
                                                                                                                    >
                                                                                                                        {
                                                                                                                            str
                                                                                                                        }
                                                                                                                    </p>
                                                                                                                )
                                                                                                            )}
                                                                                                    </Col>
                                                                                                </Row>
                                                                                            );
                                                                                        })}
                                                                                </div>
                                                                                {/*    JSON.stringify(*/}
                                                                                {/*        this.state.valutazioniIniziali*/}
                                                                                {/*    ) +*/}
                                                                                {/*        ' !==' +*/}
                                                                                {/*        JSON.stringify(this.state.sections),*/}
                                                                                {/*    JSON.stringify(*/}
                                                                                {/*        this.state.valutazioniIniziali*/}
                                                                                {/*    ) !==*/}
                                                                                {/*        JSON.stringify(this.state.sections),*/}
                                                                                {/*    this.state.annotazioniIniziali +*/}
                                                                                {/*        ' !==' +*/}
                                                                                {/*        this.state.annotazioni,*/}
                                                                                {/*    this.state.annotazioniIniziali + '' !==*/}
                                                                                {/*        this.state.annotazioni + ''*/}
                                                                                {/*)}*/}
                                                                                <div>
                                                                                    <Row>
                                                                                        <Col
                                                                                            xs="12"
                                                                                            md="12"
                                                                                            sm="12"
                                                                                            className={'mt-3'}
                                                                                        >
                                                                                            <h5>
                                                                                                Allegati
                                                                                                studente/famiglia
                                                                                            </h5>
                                                                                        </Col>

                                                                                        <Col xs="12" md="12" sm="12">
                                                                                            {this.props.evento.allegati_studenti?.filter(
                                                                                                x =>
                                                                                                    x.id_studente ==
                                                                                                    el.id
                                                                                            ).length == 0
                                                                                                ? 'Nessuno'
                                                                                                : null}
                                                                                            {this.props.evento.allegati_studenti
                                                                                                ?.filter(
                                                                                                    x =>
                                                                                                        x.id_studente ==
                                                                                                        el.id
                                                                                                )
                                                                                                .map(x => {
                                                                                                    return (
                                                                                                        <div
                                                                                                            key={x.id}
                                                                                                            style={{
                                                                                                                flexDirection:
                                                                                                                    'column-reverse',
                                                                                                                display:
                                                                                                                    'flex',
                                                                                                                alignItems:
                                                                                                                    'flex-start',
                                                                                                                justifyContent:
                                                                                                                    'flex-start'
                                                                                                            }}
                                                                                                        >
                                                                                                            <Button
                                                                                                                color="link"
                                                                                                                onClick={() =>
                                                                                                                    this.props.scaricaAllegatoPlanningStud(
                                                                                                                        x.id,
                                                                                                                        x.nome_file
                                                                                                                    )
                                                                                                                }
                                                                                                            >
                                                                                                                {x
                                                                                                                    .nome_file
                                                                                                                    .length >
                                                                                                                55
                                                                                                                    ? x.nome_file.substring(
                                                                                                                          0,
                                                                                                                          50
                                                                                                                      ) +
                                                                                                                      '...'
                                                                                                                    : x.nome_file}
                                                                                                            </Button>
                                                                                                            <i
                                                                                                                style={{
                                                                                                                    textTransform:
                                                                                                                        'uppercase'
                                                                                                                }}
                                                                                                            >
                                                                                                                {x.autore_nome +
                                                                                                                    ' ' +
                                                                                                                    x.autore_cognome +
                                                                                                                    ' - ' +
                                                                                                                    x.data_inserimento}
                                                                                                            </i>
                                                                                                        </div>
                                                                                                    );
                                                                                                })}
                                                                                        </Col>
                                                                                    </Row>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    ) : null}

                                                                    <Row style={{ marginTop: 12 }}>
                                                                        <Col xs="12" md="12" sm="12">
                                                                            <Card>
                                                                                <CardHeader>
                                                                                    Allegati docente
                                                                                </CardHeader>
                                                                                <CardBody>
                                                                                    {!this.props.readOnly && (
                                                                                        <Row>
                                                                                            <Col
                                                                                                xs="12"
                                                                                                md="12"
                                                                                                sm="12"
                                                                                            >
                                                                                                <div className="mb-2">
                                                                                                    <Button
                                                                                                        id="btnAggiungiAllegato"
                                                                                                        size="sm"
                                                                                                        color="primary"
                                                                                                        onClick={() =>
                                                                                                            this.props.mostraAggiungiAllegato(
                                                                                                                el.id,
                                                                                                                false
                                                                                                            )
                                                                                                        }
                                                                                                    >
                                                                                                        +
                                                                                                    </Button>
                                                                                                    <TooltipItem
                                                                                                        placement="right"
                                                                                                        objectId={
                                                                                                            'btnAggiungiAllegato'
                                                                                                        }
                                                                                                        content="Aggiungi allegati"
                                                                                                    />
                                                                                                </div>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    )}
                                                                                    <Row>
                                                                                        <Col xs="12" md="12" sm="12">
                                                                                            <ListGroup>
                                                                                                {this.props.evento.allegati
                                                                                                    .filter(
                                                                                                        x =>
                                                                                                            x.id_studente ==
                                                                                                            el.id
                                                                                                    )
                                                                                                    .map(allegato => {
                                                                                                        return (
                                                                                                            <ListGroupItem
                                                                                                                key={
                                                                                                                    allegato.id
                                                                                                                }
                                                                                                            >
                                                                                                                <Button
                                                                                                                    color="link"
                                                                                                                    onClick={() =>
                                                                                                                        this.props.scaricaAllegatoPlanning(
                                                                                                                            allegato.id,
                                                                                                                            allegato.nome_file
                                                                                                                        )
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        allegato.nome_file
                                                                                                                    }
                                                                                                                </Button>

                                                                                                                {!this
                                                                                                                    .props
                                                                                                                    .readOnly && (
                                                                                                                    <>
                                                                                                                        <Button
                                                                                                                            size="sm"
                                                                                                                            color="danger"
                                                                                                                            className="float-right"
                                                                                                                            onClick={() =>
                                                                                                                                this.props.removeAllegatoPlanningHandler(
                                                                                                                                    allegato.id
                                                                                                                                )
                                                                                                                            }
                                                                                                                            id={
                                                                                                                                'EliminaAllegato_' +
                                                                                                                                allegato.id
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <i className="fa fa-trash"></i>
                                                                                                                        </Button>
                                                                                                                        <TooltipItem
                                                                                                                            placement="top"
                                                                                                                            objectId={
                                                                                                                                'EliminaAllegato_' +
                                                                                                                                allegato.id
                                                                                                                            }
                                                                                                                            content="Rimuovi"
                                                                                                                        />
                                                                                                                    </>
                                                                                                                )}
                                                                                                                {allegato.idFileMappa && (
                                                                                                                    <Button
                                                                                                                        size="sm"
                                                                                                                        color="primary"
                                                                                                                        className="float-right sme-button"
                                                                                                                        style={{
                                                                                                                            position:
                                                                                                                                'static',
                                                                                                                            height: 27,
                                                                                                                            marginRight: 6
                                                                                                                        }}
                                                                                                                        onClick={() =>
                                                                                                                            scaricaSME(
                                                                                                                                allegato?.idFileMappa,
                                                                                                                                allegato?.nome_file_mappa ||
                                                                                                                                    'temp.sme',
                                                                                                                                data => {
                                                                                                                                    this.setState(
                                                                                                                                        {
                                                                                                                                            ...data
                                                                                                                                        }
                                                                                                                                    );
                                                                                                                                }
                                                                                                                            )
                                                                                                                        }
                                                                                                                        id={
                                                                                                                            'sme_' +
                                                                                                                            allegato.idFileMappa
                                                                                                                        }
                                                                                                                    >
                                                                                                                        <span
                                                                                                                            style={{
                                                                                                                                fontSize: 10
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            SME
                                                                                                                        </span>
                                                                                                                    </Button>
                                                                                                                )}
                                                                                                                <ListGroupItemText>
                                                                                                                    {allegato.descrizione
                                                                                                                        ? allegato.descrizione
                                                                                                                        : ''}
                                                                                                                </ListGroupItemText>
                                                                                                            </ListGroupItem>
                                                                                                        );
                                                                                                    })}
                                                                                            </ListGroup>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </CardBody>
                                                                            </Card>
                                                                        </Col>
                                                                    </Row>
                                                                </CardBody>
                                                            )}
                                                        </Card>
                                                    </TabPanel>
                                                </>
                                            );
                                        })}
                                        <TabPanel>
                                            {(this.props.evento.indicazioni_centro != null &&
                                                this.props.evento.indicazioni_centro != '') ||
                                            this.props.evento.allegati.filter(x => x.id_studente == -1).length > 0 ? (
                                                <Card>
                                                    <CardHeader>Indicazioni del centro</CardHeader>
                                                    <CardBody>
                                                        {this.props.evento.indicazioni_centro != null &&
                                                        this.props.evento.indicazioni_centro != '' ? (
                                                            <Row>
                                                                <Col xs="12" md="12" sm="12" className="mb-3">
                                                                    <TestoMultilinea
                                                                        testo={this.props.evento.indicazioni_centro}
                                                                        keyMap={'AnnotazioniCentro'}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        ) : null}
                                                        {this.props.evento.allegati.filter(x => x.id_studente == -1)
                                                            .length > 0 ? (
                                                            <Row>
                                                                {this.props.evento.allegati
                                                                    .filter(x => x.id_studente == -1)
                                                                    .map(allegato => {
                                                                        return (
                                                                            <Col
                                                                                xs="12"
                                                                                sm="12"
                                                                                md="12"
                                                                                lg="12"
                                                                                xl="12"
                                                                            >
                                                                                <div>
                                                                                    <Button
                                                                                        color="link"
                                                                                        onClick={() =>
                                                                                            this.props.scaricaAllegato(
                                                                                                allegato.id,
                                                                                                allegato.nome_file
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        {allegato.nome_file}
                                                                                    </Button>
                                                                                    <ButtonDropdown
                                                                                        className="float-right"
                                                                                        isOpen={
                                                                                            this.state.dropDownOpen.indexOf(
                                                                                                allegato.id
                                                                                            ) != -1
                                                                                        }
                                                                                        toggle={this.toggleDropDown.bind(
                                                                                            this,
                                                                                            allegato.id
                                                                                        )}
                                                                                    >
                                                                                        <DropdownToggle
                                                                                            caret
                                                                                            color="primary"
                                                                                        >
                                                                                            <i className="fa fa-arrow-left"></i>
                                                                                            <i className="fa fa-clone"></i>
                                                                                        </DropdownToggle>
                                                                                        <DropdownMenu>
                                                                                            <DropdownItem
                                                                                                onClick={this.props.associaAllegato.bind(
                                                                                                    this,
                                                                                                    0,
                                                                                                    allegato.id
                                                                                                )}
                                                                                            >
                                                                                                Assegna a tutti
                                                                                            </DropdownItem>
                                                                                            <DropdownItem divider />
                                                                                            {this.props.evento.studenti.map(
                                                                                                x => {
                                                                                                    return (
                                                                                                        <DropdownItem
                                                                                                            key={x.id}
                                                                                                            onClick={this.props.associaAllegato.bind(
                                                                                                                this,
                                                                                                                x.id,
                                                                                                                allegato.id
                                                                                                            )}
                                                                                                        >
                                                                                                            {'Assegna a ' +
                                                                                                                x.nome}
                                                                                                        </DropdownItem>
                                                                                                    );
                                                                                                }
                                                                                            )}
                                                                                        </DropdownMenu>
                                                                                    </ButtonDropdown>
                                                                                </div>
                                                                                <div
                                                                                    style={{
                                                                                        borderBottom:
                                                                                            '1px solid lightgray',
                                                                                        paddingBottom: '2px',
                                                                                        marginBottom: '4px'
                                                                                    }}
                                                                                >
                                                                                    {allegato.descrizione
                                                                                        ? allegato.descrizione
                                                                                        : ''}
                                                                                </div>
                                                                            </Col>
                                                                        );
                                                                    })}
                                                            </Row>
                                                        ) : null}
                                                    </CardBody>
                                                </Card>
                                            ) : null}
                                            <Card>
                                                <CardHeader>
                                                    Attività svolte
                                                    {(
                                                        this.props.evento.docenti?.find(el => el.id == idDocente) ||
                                                        this.props.forceDocente
                                                    ).presenza == 1 ? (
                                                        <div className="float-right">
                                                            <Button
                                                                size="sm"
                                                                color="danger"
                                                                onClick={this.props.annullaPresenzaDocente}
                                                            >
                                                                Annulla presenza
                                                            </Button>
                                                        </div>
                                                    ) : null}
                                                </CardHeader>
                                                <CardBody>
                                                    <Row>
                                                        <Col xs="12" md="12" sm="12">
                                                            <FormGroup>
                                                                <Input
                                                                    type="textarea"
                                                                    name="text"
                                                                    rows={3}
                                                                    value={
                                                                        this.props.evento.docenti?.find(
                                                                            el => el.id == idDocente
                                                                        ).attivita_svolte || ''
                                                                    }
                                                                    onChange={this.props.attivitaSvolteHandler}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs="12" md="12" sm="12">
                                                            <Button
                                                                color="primary"
                                                                onClick={this.props.salvaAttivitaSvolte}
                                                                disabled={!this.props.evento.attivitaSvolteModificate}
                                                            >
                                                                Salva attività svolte
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                            <Card>
                                                <CardHeader>Documenti allegati</CardHeader>
                                                <CardBody>
                                                    <Row>
                                                        <Col xs="12" md="12" sm="12">
                                                            <div className="mb-2">
                                                                <Button
                                                                    id="btnAggiungiAllegato"
                                                                    size="sm"
                                                                    color="primary"
                                                                    onClick={() => this.props.mostraAggiungiAllegato(0)}
                                                                >
                                                                    +
                                                                </Button>
                                                                <TooltipItem
                                                                    placement="right"
                                                                    objectId={'btnAggiungiAllegato'}
                                                                    content="Aggiungi allegati"
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs="12" md="12" sm="12">
                                                            <ListGroup>
                                                                {this.props.evento.allegati
                                                                    .filter(el => el.id_studente == 0)
                                                                    .map(el => {
                                                                        return (
                                                                            <ListGroupItem key={el.id}>
                                                                                <Button
                                                                                    color="link"
                                                                                    onClick={() =>
                                                                                        this.props.scaricaAllegatoPlanning(
                                                                                            el.id,
                                                                                            el.nome_file
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    {el.nome_file}
                                                                                </Button>
                                                                                <Button
                                                                                    size="sm"
                                                                                    color="danger"
                                                                                    className="float-right"
                                                                                    onClick={() =>
                                                                                        this.props.removeAllegatoPlanningHandler(
                                                                                            el.id
                                                                                        )
                                                                                    }
                                                                                    id={'EliminaAllegato_' + el.id}
                                                                                >
                                                                                    <i className="fa fa-trash"></i>
                                                                                </Button>
                                                                                <TooltipItem
                                                                                    placement="top"
                                                                                    objectId={
                                                                                        'EliminaAllegato_' + el.id
                                                                                    }
                                                                                    content="Rimuovi"
                                                                                />
                                                                                <ListGroupItemText>
                                                                                    {el.descrizione
                                                                                        ? el.descrizione
                                                                                        : ''}
                                                                                </ListGroupItemText>
                                                                            </ListGroupItem>
                                                                        );
                                                                    })}
                                                            </ListGroup>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </TabPanel>
                                    </Tabs>
                                </React.Fragment>
                            ) : null
                        ) : null}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.handleToggle}>
                            Chiudi
                        </Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}
