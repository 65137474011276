import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { useState } from 'react';
import { downloadPDF } from '../../../common/services';
import { Button } from 'reactstrap';
export default function ModaleVisualizzaAllegato({ data, isPdf, close, name }) {
    const [scale, setScale] = useState(1);
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    if (!data) {
        return <div />;
    }

    if (isPdf) {
        return (
            <div className={'dlg-modal'} style={{ opacity: data ? 1 : 0 }}>
                <div style={{ width: 1000, height: '90%', background: 'white', borderRadius: 24 }}>
                    <div className={'dlg-pdf-toolbar'}>
                        <Button color="primary" onClick={() => downloadPDF(data, 'test.pdf', true)}>
                            <i className={'fa fa-download'} />
                            &nbsp; Scarica
                        </Button>
                        &nbsp; &nbsp;
                        <Button color="danger" onClick={close}>
                            <i className={'fa fa-close'} />
                            &nbsp; Chiudi
                        </Button>
                    </div>
                    <Viewer
                        fileUrl={data}
                        plugins={
                            [
                                // Register plugins
                                // defaultLayoutPluginInstance
                            ]
                        }
                    />
                    {/*<iframe src={data} width="100%" height="100%"></iframe>*/}
                </div>
            </div>
        );
    } else {
        return (
            <div className={'dlg-modal'} style={{ opacity: data ? 1 : 0 }}>
                <div
                    style={{
                        height: '90%',
                        background: 'white',
                        borderRadius: 24,
                        overflow: 'hidden',
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <div className={'dlg-pdf-toolbar'}>
                        <Button color="primary" onClick={() => downloadPDF(data, 'test.pdf', true)}>
                            <i className={'fa fa-download'} />
                            &nbsp; Scarica
                        </Button>
                        &nbsp; &nbsp;
                        <Button color="danger" onClick={close}>
                            <i className={'fa fa-close'} />
                            &nbsp; Chiudi
                        </Button>
                    </div>
                    <div style={{ overflow: 'scroll', width: 1000, height: '90%' }}>
                        <img
                            alt={'allegato-view'}
                            style={{ objectFit: 'contain' }}
                            src={data}
                            width="100%"
                            height="100%"
                        ></img>
                    </div>
                </div>
            </div>
        );
    }
}
